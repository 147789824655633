var jsons = [
	"fashion",
	"advertising",
	"beauty",
	"portrait"
]
var posts = {
	"featured"	: [],
	"fashion"	: [],
	"advertising": [],
	"beauty" 	: [],
	"portrait"	: []
};
var page = {
	isFirst		: true,
	currentCat	: "",
	currentPage	: "",
	postCount	: 0,
	postMax 	: 0,
	isAbout 	: false,
	isDetailPage: false,
	init: function(){
		page.nav();
		page.loadAll().done(function(){
			page.checkURL();
		});
	},
	checkNav: function(){
		//console.log(page.currentCat)
		$("#nav_sp li a").removeClass("active");
		$("#nav_pc li a").removeClass("active");
		$("#nav_sp li").find("." + page.currentCat).addClass("active");
		$("#nav_pc li").find("." + page.currentCat).addClass("active");
	},
	nav: function(){
		var $body = $("body");
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic";

		$("#nav_pc li a.category").off().on("click", function(e){
			e.preventDefault();
			var cat = $(this).attr("href");
			changeCat(cat);
		});
		$("#nav_sp li a.category").off().on("click", function(e){
			e.preventDefault();
			var cat = $(this).attr("href");
			changeCat(cat);
			$html.classList.remove("show_nav");
			setTimeout(function(){
				$html.classList.remove("nav");
			},300);
		});
		function changeCat(cat){
			var flag = true;
			setTimeout(function(){
				page.changeCategory(cat);
			},400);
			$page.animate({
				scrollTop : "0px"
			},{
				"duration" 	: 1000,
				"easing" 	: ease,
				"complete"	: function(){
					if(flag){
						flag = false;
						page.checkNav();
					}
				}
			});
		}
		$("#posts li a").off().on("click", function(e){
			e.preventDefault();
			page.currentPage = $(this).attr("href");
			page.changePage();
		});
		$("a.btn_close, a.btn_close_top").off().on("click", function(e){
			e.preventDefault();
			if(page.currentCat === "about"){
				page.currentCat = "featured";
			}
			let path = "?cat=" + page.currentCat;
			window.history.pushState(null, null, path);
			page.checkURL();
		});
		//pagetop
		$("a.btn_top").off().on("click", function(){
			page.gotoTop();
		});
		$(".detail_inner").off().on("scroll", function(){
			common.scrlDetail();
		});
		$("a.btn_gototop").off().on("click", function(){
			page.gotoTop();
		});
		//prev / next
		$("#btn_prev").off().on("click", function(e){
			e.preventDefault();
			var flag = true;
			let path = "?cat=" + page.currentCat + "&page=" + $(this).attr("href");
			window.history.pushState(null, null, path);
			$("#detail").addClass("disable");
			$page.animate({
				scrollTop : "0px"
			},{
				"duration" 	: 1000,
				"easing" 	: ease,
				"complete"	: function(){
					if(flag){
						flag = false;
						page.checkURL();
					}
				}
			});
		});
		$("#btn_next").off().on("click", function(e){
			e.preventDefault();
			var flag = true;
			let path = "?cat=" + page.currentCat + "&page=" + $(this).attr("href");
			window.history.pushState(null, null, path);
			$("#detail").addClass("disable");
			$page.animate({
				scrollTop : "0px"
			},{
				"duration" 	: 1000,
				"easing" 	: ease,
				"complete"	: function(){
					if(flag){
						flag = false;
						page.checkURL();
					}
				}
			});
		});
		common.nav();
		common.resize();
		common.scrl();
	},
	changeCategory: function(url){
		if(url === "about"){
			var path = "?page=" + url;
		}else{
			var path = "?cat=" + url;
		}
		window.history.pushState(null, null, path);
		page.checkURL();
	},
	changePage: function(){
		var path = "?cat=" + page.currentCat + "&page=" + page.currentPage;
		window.history.pushState(null, null, path);
		page.checkURL();
	},
	loadAll: function(){
		var defer 	= $.Deferred();
		var count = 0;
		var featureArry = [];
		loadjson();
		function loadjson(){
			var url 	= "/assets/json/" + jsons[count] + ".json";
			var param 	= {};
			
			load.getJson(url, param).done(function(json){
				var data = json.posts;
				for(var i = 0; i < json.posts.length; i++){
					var d = data[i];
					if(d.date != ""){
						featureArry.push(d);
					}
					data[i].category = jsons[count];
					eval("posts." + jsons[count]).push(data[i]);
				}
				count++;
				if(count < jsons.length){
					loadjson();
				}else{
					object_array_sort(featureArry, "date", "desc", function(new_data){
						posts.featured = new_data;
						defer.resolve();
					});
					
				}
			});
		}
		return defer.promise();
	},
	showAbout: function(){
		$("#detail").addClass("page_about");
		page.openDetail();
	},
	addDetail: function(){
		let defer 	= $.Deferred();
		let currentData = eval("posts." + page.currentCat);
		let postIndex = 0;
		let detail = currentData.filter(function(item, index){
			//console.log(item.url, page.currentPage)
			if(item.url === page.currentPage){
				postIndex = index;
				return item;
			}
		});
		if($("#detail").hasClass("page_about")){
			$("#detail").removeClass("page_about");
		}

		let before = postIndex-1;
		let next = postIndex+1;
		before 	= before < 0 ? currentData.length-1 : before;
		next 	= next >= currentData.length ? 0 : next;
		$("#btn_prev").attr("href", currentData[before].url);
		$("#btn_next").attr("href", currentData[next].url);

		let imgArry = [];
		let detailHTML = '';
		let d = detail[0];
		if(d.movie != ""){
			detailHTML += '<div class="movie"><iframe width="560" height="315" src="https://www.youtube.com/embed/' + d.movie + '" frameborder="0" allowfullscreen></iframe></div>';
		}

		for(var i = 0; i < d.content.length; i++){
			var item = d.content[i];
			var imgpath = "/assets/images/" + item;
			imgArry.push(imgpath);
			detailHTML += `<li class="disable">
							<figure>
								<img src="${imgpath}">
							</figure>
						</li>`;
		}
		$("#detail #detail_content ul").html(detailHTML);
		$("#detail #ttl .cat").html(d.category);
		$("#detail #ttl h3").html(d.title);
		page.gotoTop();
		preload.startLoad(imgArry.length,imgArry).done(function(){
			defer.resolve();
		});
		return defer.promise();
	},
	gotoTop: function(){
		var $page 	= $(".detail_inner"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : "0px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				
			}
		});
	},
	openDetail: function(){
		let $body = $("body");
		if(page.isAbout){
			$body.addClass("isAbout");
			$("#detail").addClass("show_about");
		}else{
			if($body.hasClass("isAbout")){
				$body.removeClass("isAbout");
			}
		}
		page.gotoTop();
		if($body.hasClass("detail")){
			$("#detail").removeClass("disable");
			page.nav();
		}else{
			$body.addClass("detail");
			setTimeout(function(){
				$body.addClass("show_detail");
				page.nav();
				setTimeout(function(){
					detail.init();
				},400);
			},50);
		}
	},
	closeDetailPage: function(){
		let $body = $("body");
		$body.removeClass("show_detail");
		setTimeout(function(){
			$body.removeClass("detail");
			page.nav();
			page.gotoTop();
		}, 600);
	},
	makeHTML: function(){
		if(page.currentCat === "about"){ return false; }
		var defer 	= $.Deferred();
		var currentData = eval("posts." + page.currentCat);
		
		page.postMax = currentData.length;
		
		let HTML = '';
		var imgArry = [];
		var imgCount = 0;
		for(var i = 0; i < currentData.length; i++){
			var d = currentData[i];
			const url = d.url,
				title = d.title;
			let imgsLen = d.thumbnail.length;

			let imgs 	= "";
			const diff 	= 10;
			var zs 		= [1,2,3];
			zs = shuffle(zs);

			for(var j = 0; j < imgsLen; j++){
				const imgpath = d.thumbnail[j];
				let pos = "";
				if(imgsLen > 1){
					if(j === 0){
						pos = "transform: translate3d(" + (Math.floor(Math.random()*diff)) + "%, " + (Math.floor(Math.random()*diff)) + "%, 0)";
					}else if(j === 1){
						if(imgsLen === 2){
							pos = "transform: translate3d(" + Math.floor(Math.random()*diff)*-1 + "%, " + Math.floor(Math.random()*diff)*-1 + "%, 0)";
						}else{
							pos = "transform: translate3d(" + (Math.floor(Math.random()*diff) - diff/2) + "%, " + (Math.floor(Math.random()*diff) - diff/2) + "%, 0)";
						}
					}else{
						pos = "transform: translate3d(" + Math.floor(Math.random()*diff)*-1 + "%, " + Math.floor(Math.random()*diff)*-1 + "%, 0)";
					}
				}
				var zindex = zs[j];
				if(imgsLen === 1){
					zindex = 1;
				}
				var path = "/assets/images/" + imgpath;
				var datapoint = zindex+.1;
				var cls = "img" + imgCount;
				imgs += `<li class="scale${zindex} ${cls}" style="${pos}; z-index:${zindex}"><div class="img_wrap"><div class="img"><figure data-point="${datapoint}" style="background-image: url(${path})"><img src="${path}"></figure></div></div></li>`;
				imgArry.push(path);
				imgCount++;
			}
			HTML += `<li class="a">
						<a class="col${imgsLen}" href="${url}">
							<div class="p_i">
								<ul>${imgs}</ul>
							</div>
							<div class="ttl_wrap">
								<div class="t_i">
									<div class="ttl">
										<p class="cat">${d.category}</p>
										<h3>${title}</h3>
									</div>
								</div>
							</div>
						</a>
					</li>`;
		}
		$("#posts ul").html(HTML);
		if(page.currentCat === "featured"){
			$("#posts").removeClass("col2");
		}else{
			$("#posts").addClass("col2");
		}
		var count = 0;
		var max = imgArry.length;
		loadImg();
		function loadImg(){
			var newImg = new Image();
			newImg.src = imgArry[count];
			newImg.onload = function(){
				var _w = newImg.width;
				var _h = newImg.height;
				var ratio = _h/_w;
				$("li.img" + count).attr({
					"data-width": _w,
					"data-height": _h
				});
				count++;
				if(count < max){
					loadImg();
				}else{
					page.nav();
					common.isLoaded = true;
					defer.resolve();
				}
			}
		}
		return defer.promise();
	},
	checkURL: function(){
		var $body 	= $("body");
		var prop 	= location.pathname;
		var arg 	= new Object;
		var pair 	= location.search.substring(1).split('&');
		var hasKey	= false;
		var isChanged = false;
		var isDetailPage = false;

		for(var i = 0; pair[i]; i++) {
			var kv = pair[i].split('=');
			arg[kv[0]] = kv[1];
			if(kv[0] === "cat"){
				if(page.currentCat != kv[1]){
					isChanged = true;
				}else{
					isChanged = false;
				}
				page.currentCat = kv[1];
				hasKey = true;
				page.isAbout = false;
			}
			if(kv[0] === "page"){
				isChanged 		= false;
				isDetailPage 	= true;
				page.currentPage = kv[1];
				if(page.currentPage === "about"){
					page.isAbout = true;
					page.showAbout();
				}else{
					page.isAbout = false;
					page.addDetail().done(function(){
						page.openDetail();
					});
				}
			}
		}
		page.isDetailPage = isDetailPage;
		if(hasKey){
			
		}else{
			if(page.currentPage === "about"){
				page.currentCat = "about";
			}else{
				page.currentCat = "featured";
			}
		}
		if(!isDetailPage){
			page.closeDetailPage();
		}
		page.checkNav();
		page.postCount = 0;

		if(page.currentCat === "about"){

		}else{
			if(page.isFirst){
				page.isFirst = false;
				page.makeHTML().done(function(){
					page.resizePosts();
				});
			}else{
				if(isChanged){
					//categoryが変わったので読み込み直し
					$("#posts").addClass("disable");
					setTimeout(function(){
						page.makeHTML().done(function(){
							page.resizePosts();
						});
					},600);
				}else{

				}
			}
		}
		if(page.currentCat != "featured"){
			if(page.isAbout){
				document.title =  "ABOUT | KYOKO MUNAKATA PHOTOGRAPHY";
			}else{
				var pageName = page.currentCat.toUpperCase();
				document.title =  pageName + " | KYOKO MUNAKATA PHOTOGRAPHY";
			}
			
		}else{
			document.title = "KYOKO MUNAKATA PHOTOGRAPHY";
		}
	},
	resizePosts: function(){
		$("#posts").removeClass("disable");
		if(page.currentCat != "featured"){
			if(isSmallScreen){
				$("#posts li.a").css({
					"height": ""
				});
				$("#posts li.a .img figure").css({
					"width": "",
					"padding-bottom": ""
				});
			}else{
				$("#posts li.a").each(function(i){
					if(i%2 === 0){
						var $ablock = $("#posts li.a").eq(i);
						var $bblock = $("#posts li.a").eq(i+1);
						var aClass = $ablock.find("a").hasClass("col1");
						var bClass = $bblock.find("a").hasClass("col1");
						
						if(aClass || bClass){
							var a = $ablock.find(".p_i").height()+30 + $ablock.find(".ttl_wrap").height();
							var b = $bblock.find(".p_i").height()+30 + $bblock.find(".ttl_wrap").height();
							var maxH = Math.max(a,b);
							if(!maxH){
								maxH = $ablock.find(".p_i").height()+30 + $ablock.find(".ttl_wrap").height();
							}
						}else{
							var per = 0.75;
							var maxH = Math.floor($("#posts li.a").width()*per);
						}
						$("#posts li.a").eq(i).css({
							"height": maxH + "px"
						});
						$("#posts li.a").eq(i+1).css({
							"height": maxH + "px"
						});
					}
					$(this).find(".p_i li").each(function(i){
						var $this = $(this);
						var _w = $(this).attr("data-width");
						var _h = $(this).attr("data-height");
						var ratio = _h/_w;
						//1カラムで縦長写真は小さくする
						if(ratio > 1.3){
							if($this.parent().parent().parent().hasClass("col1")){
								$this.addClass("vertical");
								if(isSmallScreen){
									
								}else{
									$this.find("figure").css({
										"width": _w/_h*100 + "%",
										"padding-bottom": "100%"
									});
								}
							}
						}
					})
				});
			}
			
		}else{
			$("#posts li.a").css({
				"height": ""
			});
		}
	}
}
function object_array_sort(data,key,order,fn){
	//デフォは降順(DESC)
	var num_a = -1;
	var num_b = 1;

	if(order === 'asc'){//指定があれば昇順(ASC)
		num_a = 1;
		num_b = -1;
	}

	data = data.sort(function(a, b){
	var x = a[key];
	var y = b[key];
	if (x > y) return num_a;
	if (x < y) return num_b;
		return 0;
	});

	fn(data); // ソート後の配列を返す
}