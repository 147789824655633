var Dblock = {
	top: 0,
	show: 0
}
var dblocks = [];
var aboutAnimations = [];
var AboutAnimation = {
	top: 0,
	show: 0
}
var detail = {
	uiTop: 0,
	worksTop: 0,
	init: function(){
		detail.resize();
		setTimeout(function(){
			detail.resize();
			detail.scrl();
		},300);
		$("#detail .detail_inner").off().on("scroll touchmove", function(){
			detail.scrl();
		});
	},
	resize: function(){
		$("#about .block_prof").css({
			"height": ""
		});
		if(isSmallScreen){
		}else{
			$("#about .block_prof").css({
				"height": $(".img_prof").height()*1.8 + "px"
			});
		}
		dblocks = [];
		$("#detail_content li").each(function(){
			var _db = object(Dblock);
			_db.top 	= Math.floor($(this).offset().top);
			_db.show 	= Math.floor(_db.top + $(window).height()/3);
			dblocks.push(_db);
		});
		if(page.isAbout){
			aboutAnimations = [];
			$("#detail #about .a").each(function(i){
				var _a = object(AboutAnimation);
				_a.top = $(this).offset().top;
				_a.show 	= Math.floor(_a.top + $(window).height()/3);
				aboutAnimations.push(_a);
			});
		}
	},
	scrl: function(){
		var topD = $(".detail_inner").scrollTop(),
			btmD = topD + $(window).height(),
			$body = $("body");
		if(btmD > $(".wrap_detail").height() - $(".copy_detail").outerHeight() - 40){
			if(!$body.hasClass("hide_close")){
				$body.addClass("hide_close");
			}
		}else{
			if($body.hasClass("hide_close")){
				$body.removeClass("hide_close");
			}
		}
		var linedTop = 0;
		var h1Diff = $("h1 a").height() - $("h1").offset().top;
		if(page.isAbout){
			linedTop = $(".about_inner").offset().top + topD - h1Diff
			
			var aa 	= aboutAnimations;
			var alen = aa.length;
			for(var a = 0; a < alen; a++){
				if(a === alen - 1){
					if(btmD >= aa[a].show){
						activeAnimation(a);
					}
				}else{
					if(btmD >= aa[a].show){
						activeAnimation(a);
					}
				}
			}
			function activeAnimation(a){
				var b = $("#detail .a").eq(a);
				if(!b.hasClass("animate")){
					b.addClass("animate");
					setTimeout(function(){
						b.addClass("animated");
					},1000);
				}
			}
			var prof_imgY = 0;
			if(isSmallScreen){
				prof_imgY 	= 0;
			}else{
				var per01 	= ( (topD - $(".block_prof").offset().top) /$(".block_prof").outerHeight() );
				prof_imgY 	= topD*.4;
				//parallax
				if(per01 > .2){
					$(".img_prof ul").addClass("active1");
				}else{
					$(".img_prof ul").removeClass("active1");
				}
				detail.worksTop = $(".block_works").offset().top + topD;
				let per02 = ( btmD - detail.worksTop) /$(".block_works").outerHeight();
				//console.log(per02)
				if(per02 > 1){
					$(".block_works ul").addClass("active1");
				}else{
					$(".block_works ul").removeClass("active1");
				}
			}
			$(".img_prof").css({
				"transform": "translate3d(0," + prof_imgY + "px, 0)"
			});
		}else{
			var detailTop 	= $("#detail_content").css("top").replace("px", "");
			linedTop 		= parseInt(detailTop);
			for(var a = 0; a < dblocks.length; a++){
				if(a === dblocks.length - 1){
					if(btmD >= dblocks[a].show){
						active(a)
					}
				}else{
					if(btmD >= dblocks[a].show && btmD < dblocks[a+1].show){
						active(a)
					}
				}
			}
			function active(a){
				var b = $("#detail_content li").eq(a);
				if(b.hasClass("disable")){
					b.removeClass("disable");
				}
			}
		}
		if(topD >= linedTop){
			if(!$body.hasClass("lined")){
				$body.addClass("lined");
			}
		}else{
			if($body.hasClass("lined")){
				$body.removeClass("lined");
			}
		}
	}
}