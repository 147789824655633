var isPage = "";
var Animate = {
	top: 0,
	show: 0
}
var animations = [];
var common = {
	isLoaded: false,
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		page.init();
		common.resize();
		
		common.mouseCursor();
		$(document).off().on("scroll", function(){
			common.scrl();
		});

		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			//console.log(page.isDetailPage)
			if(page.isDetailPage){
				detail.resize();
			}
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
		let $body = $("body");
		$(".gototop a").off().on("click", function(){
			common.scrollToPosition(0);
		});
		
		
		$("#btn_menu").off().on("click", function(){
			if(!$html.classList.contains("nav")){
				$html.classList.add("nav");
				setTimeout(function(){
					$html.classList.add("show_nav");
				},200);
			}else{
				$html.classList.remove("show_nav");
				setTimeout(function(){
					$html.classList.remove("nav");
				},300);
			}
		});
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		function shareTxt(num,shareUrl){
			var domain = "http://" + document.domain;
			var url 	= domain + shareUrl;
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		

		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		//console.log(isSmallScreen);
		if($("#posts li.a").length > 0){
			if(common.isLoaded){
				page.resizePosts();
			}
		}
		common.checkBlock();
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	checkBlock: function(){
		animations = [];
		$("#posts .a").each(function(){
			var _a = object(Animate);
			_a.top = Math.floor($(this).offset().top);
			_a.show = Math.floor(_a.top + $(window).height()/3);
			animations.push(_a);
		});
		//console.log(animations);
	},
	scrollToPosition: (pos) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : pos + "px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: function(){
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height(),
			$body = $("body");
		if($body.hasClass("detail")){ return false}
		if(topD >= $("#posts").offset().top){
			if(!$body.hasClass("lined")){
				$body.addClass("lined");
			}
		}else{
			if($body.hasClass("lined")){
				$body.removeClass("lined");
			}
		}
		let alen = animations.length;
		for(var a = 0; a < alen; a++){
			if(a === alen - 1){
				if(btmD >= animations[a].show){
					activeBlock(a)
				}
			}else{
				if(btmD >= animations[a].show){
					activeBlock(a)
				}
			}
		}
		function activeBlock(a){
			var $block = $(".a").eq(a);
			if(!$block.hasClass("animate")){
				$block.addClass("animate");
				setTimeout(function(){
					$block.addClass("animated");
				},1000);
			}
		}
	},
	scrlDetail: function(){
		var topD = $("#detail .detail_inner").scrollTop(),
			btmD = topD + $(window).height(),
			$body = $("body");

		if(topD >= $("#detail_content").offset().top){
			if(!$body.hasClass("lined")){
				$body.addClass("lined");
			}
		}else{
			if($body.hasClass("lined")){
				$body.removeClass("lined");
			}
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			let winW = $(window).width(),
				winH = $(window).height(),
				half = winW / 2;
			mouse[0] = Math.floor(e.clientX/winW*100)- 50;
			mouse[1] =  Math.floor(e.clientY/winH*100)- 50;
			if(page.currentCat === "featured"){
				$("li:hover figure").each(function(i){
					var point = $(this).attr("data-point");
					$(this).css({
						"transform": "translate3d(" + -(mouse[0]/10*$(this).attr("data-point")/2) + "%, " + -(mouse[1]/20*point*point/4) + "%,0)"
					});
				});
			}
			
			//console.log(mouse)
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});

function shuffle(array) {
	var n = array.length, t, i;

	while (n) {
		i = Math.floor(Math.random() * n--);
		t = array[n];
		array[n] = array[i];
		array[i] = t;
	}

	return array;
}
